@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
body {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/static/media/openbanner.c49dba16.jpg) 0 0 / cover no-repeat fixed rgba(0, 0, 0, 0);
}
@media (max-width: 767.98px) {
  body {
    /*background: #000;*/
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.thumbs-up-active,
.thumbs-up-inactive,
.thumbs-down-active,
.thumbs-down-inactive {
  cursor: pointer;
}
.user-description {
  /*display: block;
  width: 100%;
  height: 100px;
  margin-bottom: 40px;
  text-align: left;
  width: 66%;*/
  height: 100px;
  margin-bottom: 40px;
}
/** customize css starts **/
.btn-lg {
  width: 375px;
  color: #ffffff;
}
@media (max-width: 767.98px) {
  .btn-lg {
    width: 280px;
  }
  .user-description {
    width: 66%;
    height: 100px;
    margin-bottom: 40px;
  }
}
.btn-default {
  background-color: #6e6e6e;
}
/** customize css ends **/
/** Global CSS Starts **/
.hidden {
  display: none;
}
.mb-50 {
  margin-bottom: 50px;
}
.mr-100px {
  margin-right: 26px;
}
.pb-50 {
  padding-bottom: 50px;
}
.mb-20 {
  margin-bottom: 20px;
}
.font-size-medium {
  font-size: 18px;
}
.font-size-xlarge {
  font-size: 64px;
}
h1 {
  font-size: 2.25rem;
}
@media (max-width: 767.98px) {
  h1 {
    font-size: 1.75rem;
  }
}
/** Global CSS Starts **/
::-webkit-input-placeholder {
  text-align: center;
}
:-moz-placeholder { /* Firefox 18- */
  text-align: center;  
}
::-moz-placeholder {  /* Firefox 19+ */
  text-align: center;  
}
:-ms-input-placeholder {  
  text-align: center; 
}
/****** Style Star Rating Widget *****/

.fieldset {
  display: inline-block;
}

label {
  margin: 0;
  padding: 0;
}

.star {
  float: left;
  width: 100%;
  text-align: center;
}

.star-rating {
  cursor: pointer;
}

.rating {
}

.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  padding: 25px;
  font-size: 2em;
  font-family: FontAwesome;
  display: inline-block;
  content: '\F005';
}

@media (max-width: 767.98px) {
  .rating > label:before {
    padding: 12px;
  }
}

.rating > .half:before {
  content: '\F089';
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}

/***** Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
} /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label {
  color: #ffed85;
}



/* Style for Stars

.rating {
  line-height: 1;
  display: inline-block;
}
.rating__item {
  display: inline;
  font-size: 0;
}
.rating__item::before {
  font-size: 18px;
  content: "★";
  color: lightgray;
}
.rating__input {
  position: absolute;
  visibility: hidden;
}
.rating:hover .rating__item::before {
  color: hotpink;
}
.rating__item:hover ~ .rating__item::before {
  color: lightgray;
}
.rating__item--selected::before {
  color: hotpink;
}
*/
.thumbs-up-down-section {
  margin-bottom: 26px;
}
.thank-you-section {
   width: 70%;
   margin: auto;
	  
   padding: 120px;
   background-color:  #0387CF;
}

@media only screen and (max-width: 600px) {
  .thank-you-section {
    width: 90%;
    margin: auto;
	  padding: 50px;
	  background-color:  #0387CF;
  }
}
.App {
  /*width: 800px;*/
  margin: 0 auto;
  text-align: center;
}
.Button_center {
    display: inline-block;
   width:450px;
}
.Star_custom{
	margin:80px 0px;
}
.img-fluid{
  max-width: 18%;
    height: auto;
    padding:6px;
}	
@media (max-width: 991.98px) {
  .App {
    /*width: 600px;*/
    width: 100%;
  }
  .img-fluid{
  max-width:33%;
    height: auto;
    padding:6px;
}	
}
@media (max-width: 767.98px) {
  .App {
    /*width: 320px;*/
    width: 100%;
  }
  .img-fluid{
  max-width:53%;
    height: auto;
    padding:6px;
}	
}
.App-header {
  margin-bottom: 80px;
  background: #FFFFFF;
  padding: 10px 0px 12px;
  /*padding-top: 46px;
  padding-bottom: 20px;*/
}
@media (max-width: 767.98px) {
  .App-header {
    margin-bottom: 60px;
  }
  .Button_center {
    display: inline-block;
   width:320px;
}
}
.App-link {
  color: #61dafb;
}
.pagination-section {
  color: #FFFFFF;;
  text-align: center;
}
.pagination-section-text{
  padding-top: 10px;
}


.btn-info {
  display: block;
  margin: 0 auto;
}

.userName {
  width: 50%;
  height: 40px;
}

.emailID {
  width: 50%;
  height: 40px;
}

