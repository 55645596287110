.thank-you-section {
   width: 70%;
   margin: auto;
	  
   padding: 120px;
   background-color:  #0387CF;
}

@media only screen and (max-width: 600px) {
  .thank-you-section {
    width: 90%;
    margin: auto;
	  padding: 50px;
	  background-color:  #0387CF;
  }
}