@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

/****** Style Star Rating Widget *****/

.fieldset {
  display: inline-block;
}

label {
  margin: 0;
  padding: 0;
}

.star {
  float: left;
  width: 100%;
  text-align: center;
}

.star-rating {
  cursor: pointer;
}

.rating {
}

.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  padding: 25px;
  font-size: 2em;
  font-family: FontAwesome;
  display: inline-block;
  content: '\f005';
}

@media (max-width: 767.98px) {
  .rating > label:before {
    padding: 12px;
  }
}

.rating > .half:before {
  content: '\f089';
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}

/***** Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
} /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label {
  color: #ffed85;
}



/* Style for Stars

.rating {
  line-height: 1;
  display: inline-block;
}
.rating__item {
  display: inline;
  font-size: 0;
}
.rating__item::before {
  font-size: 18px;
  content: "★";
  color: lightgray;
}
.rating__input {
  position: absolute;
  visibility: hidden;
}
.rating:hover .rating__item::before {
  color: hotpink;
}
.rating__item:hover ~ .rating__item::before {
  color: lightgray;
}
.rating__item--selected::before {
  color: hotpink;
}
*/