.btn-info {
  display: block;
  margin: 0 auto;
}

.userName {
  width: 50%;
  height: 40px;
}

.emailID {
  width: 50%;
  height: 40px;
}
