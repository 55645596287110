body {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(../src/images/openbanner.jpg) 0 0 / cover no-repeat fixed rgba(0, 0, 0, 0);
}
@media (max-width: 767.98px) {
  body {
    /*background: #000;*/
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.thumbs-up-active,
.thumbs-up-inactive,
.thumbs-down-active,
.thumbs-down-inactive {
  cursor: pointer;
}
.user-description {
  /*display: block;
  width: 100%;
  height: 100px;
  margin-bottom: 40px;
  text-align: left;
  width: 66%;*/
  height: 100px;
  margin-bottom: 40px;
}
/** customize css starts **/
.btn-lg {
  width: 375px;
  color: #ffffff;
}
@media (max-width: 767.98px) {
  .btn-lg {
    width: 280px;
  }
  .user-description {
    width: 66%;
    height: 100px;
    margin-bottom: 40px;
  }
}
.btn-default {
  background-color: #6e6e6e;
}
/** customize css ends **/
/** Global CSS Starts **/
.hidden {
  display: none;
}
.mb-50 {
  margin-bottom: 50px;
}
.mr-100px {
  margin-right: 26px;
}
.pb-50 {
  padding-bottom: 50px;
}
.mb-20 {
  margin-bottom: 20px;
}
.font-size-medium {
  font-size: 18px;
}
.font-size-xlarge {
  font-size: 64px;
}
h1 {
  font-size: 2.25rem;
}
@media (max-width: 767.98px) {
  h1 {
    font-size: 1.75rem;
  }
}
/** Global CSS Starts **/
::-webkit-input-placeholder {
  text-align: center;
}
:-moz-placeholder { /* Firefox 18- */
  text-align: center;  
}
::-moz-placeholder {  /* Firefox 19+ */
  text-align: center;  
}
:-ms-input-placeholder {  
  text-align: center; 
}