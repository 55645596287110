.App {
  /*width: 800px;*/
  margin: 0 auto;
  text-align: center;
}
.Button_center {
    display: inline-block;
   width:450px;
}
.Star_custom{
	margin:80px 0px;
}
.img-fluid{
  max-width: 18%;
    height: auto;
    padding:6px;
}	
@media (max-width: 991.98px) {
  .App {
    /*width: 600px;*/
    width: 100%;
  }
  .img-fluid{
  max-width:33%;
    height: auto;
    padding:6px;
}	
}
@media (max-width: 767.98px) {
  .App {
    /*width: 320px;*/
    width: 100%;
  }
  .img-fluid{
  max-width:53%;
    height: auto;
    padding:6px;
}	
}
.App-header {
  margin-bottom: 80px;
  background: #FFFFFF;
  padding: 10px 0px 12px;
  /*padding-top: 46px;
  padding-bottom: 20px;*/
}
@media (max-width: 767.98px) {
  .App-header {
    margin-bottom: 60px;
  }
  .Button_center {
    display: inline-block;
   width:320px;
}
}
.App-link {
  color: #61dafb;
}
.pagination-section {
  color: #FFFFFF;;
  text-align: center;
}
.pagination-section-text{
  padding-top: 10px;
}

